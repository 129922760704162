import React, { useState, useMemo } from "react"
import { navigate } from "gatsby"

import { useApp } from "../../hooks/useApp"
import { useTemplate } from "../../hooks/useTemplate"
import { useSanityMentor } from "../../hooks/useSanity"
import { useAnalytics } from "../../hooks/useAnalytics"

export const withMentorRequest = Component =>
  React.memo(({ name = "MentorRequest", mentor, ...props }) => {
    const [selected, setSelected] = useState([])
    const { member } = useApp()
    const { trackCustomEvent } = useAnalytics()
    const { mentor: template } = useTemplate()
    const { createMentorRequest, getMentor, loading } = useSanityMentor()

    const handleSubmit = async () => {
      const data = selected
      Object.keys(selected)?.map(item => (data[item] = data[item]?.filter(item => item !== "Other: ")))
      const success = await createMentorRequest({ mentor, data })
      if (success) {
        trackCustomEvent("mentor_booking", {
          skill_name: data?.help || [],
          member_id: member?._id || "",
          organisation_name: member?.company || "",
        })

        navigate("/mentors/requested")
        getMentor({ email: member?.contact?.email })
      }
    }

    const handleSelect = ({ id, value, singleOnly = false, isOther = false }) => {
      setSelected(prevState => ({
        ...prevState,
        [id]: prevState?.[id]?.includes(value)
          ? prevState?.[id]?.filter(item => item !== value)
          : prevState?.[id]?.length && !singleOnly
          ? value.includes("Other: ")
            ? [...prevState?.[id]?.filter(item => !item.includes("Other: ")), value]
            : [...prevState?.[id], value]
          : [value],
      }))
    }

    Component.displayName = name
    return useMemo(
      () => (
        <Component
          {...props}
          mentor={mentor}
          template={template}
          selected={selected}
          loading={loading}
          handleSubmit={handleSubmit}
          handleSelect={handleSelect}
        />
      ),
      [mentor, selected, loading]
    )
  })
